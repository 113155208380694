// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Table, PageHeader, Button, Empty, Card, Space, Alert, Spin } from 'antd';
import { SUPPLIER_FRAGMENT } from '../../../utils/fragments';
import { gql, useQuery } from '@apollo/client';
import useI18n from '../../../utils/useI18n';
import { FileExcelOutlined, InfoCircleTwoTone, LoadingOutlined, PlusOutlined, ToTopOutlined } from '@ant-design/icons';
import { OrderItemBatchType } from '../../types';
import { useHistory } from 'react-router-dom';
import useOrderItemBatch from '../../../utils/hooks/useOrderItemBatch';
import UserContext from '../../../utils/UserContext';
import moment from 'moment';
import exportXlsx from '../../../utils/hooks/exportXlsx';
import { ExcelsiorImportModal } from '@9troisquarts/wand';

const GET_SUPPLIER_QUERY = gql`
  query supplierByToken($token: String!) {
    supplierByToken(token: $token) {
      ...SupplierInfo
      orderItemsWithoutBatchCount
      batches {
        id
        status
        comment
        orderItemsCount
        awaitingOrderItemsCount
        createdAt
      }
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

const SupplierDashboard = props => {
  const {
    match: {
      params: {
        token
      }
    }
  } = props;

  const { t } = useI18n();
  const history = useHistory();

  const user: any = useContext(UserContext);
  const { data, loading, refetch } = useQuery(GET_SUPPLIER_QUERY, { 
    fetchPolicy: 'network-only',
    variables: { token: token } 
  });
  const supplier = data?.supplierByToken

  const { onSave: onCreateBatch } = useOrderItemBatch();

  const [createBatchLoading, setCreateBatchLoading] = useState(false)

  const onGenerateBatchAndDownload = () => {
    setCreateBatchLoading(true)
    onCreateBatch(
      {
        supplierId: supplier?.id
      }, {
        onCompleted: (data: any) => {
          const batch = data?.createOrderItemBatch?.orderItemBatch;
          refetch();
          onExport(batch.id);
          setCreateBatchLoading(false)
        }
      }
    )
  }

  const [isXlsxImportModalVisible, setIsXlsxImportModalVisible] = useState<boolean>(false);
  const [currentXlsxImportId, setCurrentXlsxImportId] = useState<ID | undefined>(undefined);
  const [importer, setImporter] = useState<String>(undefined)

  const onNewXlsxImport = (importer: string) => {
    setImporter(importer)
    setIsXlsxImportModalVisible(true);
    setCurrentXlsxImportId(undefined);
  }

  const onCloseXlsxImport = () => {
    setIsXlsxImportModalVisible(false);
    setCurrentXlsxImportId(undefined);
  }

  if (loading && !supplier) return(
    <div
      style={{ marginTop: '50px', textAlign: 'center' }}
    >
      <Spin />
    </div>
  )

  if (!loading && !supplier) return(
    <>
      <Empty
        style={{ marginTop: '50px' }}
        description="La page fournisseur n'existe pas. Merci de vérifier votre lien d'accès."
      />
    </>
  )

  const onExport = (batchId, awaitingOnly = false) => {
    exportXlsx('/export/order_items')({
      supplierId: supplier.id,
      batchId,
      awaitingOnly
    });
  }

  const batchColumns = [
    {
      title: "Date de création du lot",
      key: 'createdAt',
      render: (_t, batch: OrderItemBatchType) => moment(batch.createdAt).format('L')
    },
    {
      title: "Numéro de lot",
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: "Nombre de produits",
      key: 'quantity',
      render: (_t, batch: OrderItemBatchType) => `${parseInt(batch.orderItemsCount) - parseInt(batch.awaitingOrderItemsCount)} / ${batch.orderItemsCount} produits`
    },
    {
      title: "Récapitulatif commande",
      key: 'exportAll',
      render: (_t, batch: OrderItemBatchType) => (
        <a onClick={() => onExport(batch.id)}>
          <Space>
            <FileExcelOutlined style={{ color: '#008000' }} />
            {t('sentences.download_all_order_items')}
            <div>
              ({batch.orderItemsCount})
            </div>
          </Space>
        </a>
      )
    },
    {
      title: "Reste à faire",
      key: 'exportAwaiting',
      render: (_t, batch: OrderItemBatchType) => (
        <a onClick={() => onExport(batch.id, true)}>
          <Space>
            <FileExcelOutlined style={{ color: '#008000' }} />
            {t('sentences.download_awaiting_order_items')}
            <div>
              ({batch.awaitingOrderItemsCount})
            </div>
          </Space>
        </a>
      )
    },
  ]

  return (
    <>
      {isXlsxImportModalVisible && (
        <ExcelsiorImportModal
          open={isXlsxImportModalVisible}
          importType={importer}
          title={t(`menu.importers.${importer}`)}
          onCancel={onCloseXlsxImport}
          translate={t}
          onCompleted={refetch}
        />
      )}
      <PageHeader
        key='page-header'
        title={supplier.title}
        onBack={user?.id
          ? () => history.push('/suppliers')
          : false
        }
      />
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
        {supplier.orderItemsWithoutBatchCount > 0 &&
          <Card
            className='mb-3'
          >
            <Space>
              <InfoCircleTwoTone />
              {t('sentences.supplier_generate_and_download_batch', { count: supplier.orderItemsWithoutBatchCount })}
              { supplier.sendType === 'email' &&
                <Button
                  type='primary'
                  key="generate_batch"
                  onClick={() => onGenerateBatchAndDownload()}
                  disabled={createBatchLoading}
                >
                  {t('sentences.create_batch')}
                </Button>
              }
            </Space>
          </Card>
        }
        {supplier.batches && 
          <Card 
            title={(
              <>
                Lots en cours
                { supplier.allowImport && 
                   <Button 
                    type='primary'
                    icon={<PlusOutlined />}
                    key="add"
                    onClick={() => onNewXlsxImport('supplier_data')}
                    style={{ marginLeft: '28px'}}
                  >
                    {t('sentences.import_supplier_file')}
                  </Button>
                }

                { supplier.sendType === 'compiere' && user?.id &&
                  <Button
                    type='primary'
                    icon={<ToTopOutlined />} 
                    key="add_compiere" 
                    onClick={() => onNewXlsxImport('compiere_data')}
                    style={{ marginLeft: '28px'}}
                  >
                    Importer votre fichier de retour compiere
                  </Button>
                }
               
              </>
            )}
          >
            <div className="flex">
              <Alert 
                message={t('sentences.batch_info')}
                type="info"
                showIcon
                className='mb-4'
              />
            </div>
            <Table
              key='batchesTable'
              pagination={false}
              columns={batchColumns}
              dataSource={supplier.batches}
              rowKey='id'
              size='small'
            />
          </Card>
        }
      </Spin>
      
    </>
  )
}

export default SupplierDashboard;